import React from "react";
import SEO from "../../components/common/seo";
import {graphql, Link} from "gatsby";
import LayoutFourth from "../../components/common/layout-fourth";
import {StaticImage} from "gatsby-plugin-image";
import {OutboundLink} from "gatsby-plugin-google-gtag";

const RequestDemo = ({pageContext, data}) => {
    function initializeHubspotForm() {
        if (typeof window !== 'undefined' && 'hbspt' in window) {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "20249583",
                formId: "7c9da40e-3982-4492-a64c-a28d10d027f8",
                target: "#rad-form-container"
            });
        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }

    initializeHubspotForm();

    return (
        <LayoutFourth pageContext={pageContext} cookies={data.cookies}>
            <SEO lang="en" title="Request a demo - Tecalis"/>

            <header id="header" className="rad-header">
                <div className="container" style={{minHeight: 442}}>
                    <StaticImage src={"../../img/brand.png"} alt="Tecalis" width={160} height={39} className="mt-10"/>
                    <h1 className="mt-9">Request a Demo</h1>
                    <p className="rad-p">See for yourself what Tecalis can do for your business.</p>

                    <lottie-player class="rad-animation" src="https://assets7.lottiefiles.com/packages/lf20_u6ekhjhj/data.json" background="transparent" speed="1" loop autoplay/>
                </div>
            </header>

            <section className="rad-form-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-12 mb-lg-0 order-lg-6">
                            <div id="rad-form-container"/>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <h2>Schedule a Personalized Live Demonstration</h2>

                            <p className="rad-p">
                                Fill in the form, and within one business day, one of our representatives will schedule a customized demo based on your organization's unique
                                challenges, needs, and product interests.
                            </p>

                            <div align="center" className="logo-wall rad-wall">
                                <img src={require("../../logos/telefonica.png").default} alt="Telefonica"/>
                                <img src={require("../../logos/prosegur.png").default} alt="Prosegur"/>
                                <img src={require("../../logos/claro.png").default} alt="Claro"/>
                                <img src={require("../../logos/vodafone.png").default} alt="Vodafone"/>
                                <img src={require("../../logos/enel.png").default} alt="Enel"/>
                                <img src={require("../../logos/valeo.png").default} alt="Valeo"/>
                                <img src={require("../../logos/orange.png").default} alt="Orange"/>
                            </div>

                            <div className="rad-concept">
                                <h3>What can you expect?</h3>

                                <ul>
                                    <li>A brief conversation to pinpoint your business and users' needs</li>
                                    <li>Live demonstration of our solutions tailored to the key use cases in your industry, including customer success stories</li>
                                    <li>We will answer all your questions to make sure you have all the information you need. No commitment whatsoever</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="rad-hero-text">
                <div>Take the lead in your industry,</div>
                <div className="text-tecalis">let us show you how</div>
            </section>

            <footer className="rad-footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-12">
                            <StaticImage src={"../../img/brand.png"} alt="Tecalis" width={103} height={25} className="tecalis-logo"/>

                            <p>
                                © 2021 Tecalis. All rights reserved.<br/>
                                <Link to="/es/legal/condiciones-uso">Terms of use</Link> | <Link to="/es/legal/privacidad">Notice of Privacy</Link>
                            </p>

                            <ul className="list-unstyled list-inline list-social mt-3 mb-6 mb-md-0">
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.facebook.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-facebook"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://twitter.com/tecalis" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-twitter"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.linkedin.com/company/tecalis/" className="text-decoration-none" target="_blank" rel="noopener noreferrer me">
                                        <i className="fe fe-linkedin"/>
                                    </OutboundLink>
                                </li>
                                <li className="list-inline-item list-social-item mr-7">
                                    <OutboundLink href="https://www.instagram.com/tecalis/" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-instagram"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item">
                                    <OutboundLink href="https://www.youtube.com/c/Tecalis" target="_blank" rel="noopener noreferrer me" className="text-decoration-none">
                                        <i className="fe fe-youtube"/>
                                    </OutboundLink>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-4 d-none d-lg-block text-center">
                            <a href="#header" className="smooth-scroll">
                                <StaticImage src={"../../img/ic_arrow_up.png"} alt="Tecalis" className="mt-6"/>
                                <br/>
                                Go back up
                            </a>
                        </div>

                        <div className="col-lg-3 col-md-4 col-auto offset-lg-1 offset-md-2">
                            <ul className="iso">
                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=199259" rel="noopener noreferrer" target="_blank">
                                        <img src={require("../../img/9001-nuevo.svg").default} width="80" alt="iso9001"/>
                                    </OutboundLink>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=207780" target="_blank" rel="noreferrer">
                                        <img src={require("../../img/27001-nuevo.svg").default} width="80" alt="iso27001"/>
                                    </OutboundLink>
                                </li>
                            </ul>

                            <ul className="iso">
                                <li className="list-inline-item list-social-item mr-1">
                                    <img src={require("../../img/ic_cer_9001.png").default} width="80" alt="cer9001"/>
                                </li>

                                <li className="list-inline-item list-social-item mr-1">
                                    <img src={require("../../img/ic_eidas.png").default} width="80" alt="eidas"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

        </LayoutFourth>
    )
}

export default RequestDemo

export const requestDemoQuery = graphql
    `query($lang: String) {
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
